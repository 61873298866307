import { Canvas } from '@react-three/fiber';
import React from "react";
import {OrbitControls, Stage, PerspectiveCamera, Loader, Environment} from "@react-three/drei";
import { Suspense } from "react";
import Model from "./models/Beer";
import { EffectComposer, Bloom, ToneMapping } from '@react-three/postprocessing'
import './App.css';


function App() {
    const ref = React.useRef()
    const cameraRef = React.useRef();

    return (
        <div className="App">
            {/*<img className="Logo" src={logo} alt="logo" />*/}
            {/*    <Canvas dpr={[1, 2]} camera={{ fov: 50, position: [0, -0, 0] }} >*/}

            {/*        <PerspectiveCamera  ref={cameraRef}   fov={10} position={[-1, 6, 5]} makeDefault />*/}
            {/*        <Suspense fallback={null}>*/}
            {/*            <Stage adjustCamera={false} controls={ref} preset="portrait" intensity={0}    shadows={false} environment="apartment">*/}
            {/*                <group position={[0, 2, 0]}>*/}
            {/*                    <Beer />*/}
            {/*                </group>*/}
            {/*            </Stage>*/}
            {/*        </Suspense>*/}
            {/*        <OrbitControls autoRotate={true} enableTouchScroll={true} maxDistance={80}   enableRotate={false} minDistance={2} enableZoom={true} enablePan={false} target={[0, 0, 0]} />*/}
            {/*    </Canvas>*/}
            <Canvas dpr={[1, 2]} camera={{ fov: 35, position: [0, -0, 10] }}>
                {/*<Environment*/}
                {/*    preset="apartment"*/}
                {/*    background*/}
                {/*    backgroundBlurriness={0.5}*/}
                {/*/>*/}
                <ambientLight intensity={0.5} color="#ffffff"  />
                        {/*<PerspectiveCamera  ref={cameraRef}   fov={10} position={[-40, -0, 0]} makeDefault />*/}
                <Suspense fallback={null}>
                    <Stage  adjustCamera={false} controls={ref} preset="portrait" shadows={false} >
                        {/*<directionalLight position={[1, 5, 20]} intensity={0.2} color="#AB47BC" />*/}
                        false
                        <Model />
                        false
                    </Stage>
                </Suspense>

                <EffectComposer disableNormalPass multisampling={4}>
                    {/** The bloom pass is what will create glow, always set the threshold to 1, nothing will glow
                     /*  except materials without tonemapping whose colors leave RGB 0-1 */}
                    <Bloom mipmapBlur luminanceThreshold={1} intensity={0.1} />
                    {/*<ToneMapping adaptive resolution={256} middleGrey={0.5} maxLuminance={15.0} averageLuminance={1} adaptationRate={1} />*/}
                </EffectComposer>
                <OrbitControls autoRotate={true} autoRotateSpeed={0.6} enableTouchScroll={true} maxDistance={80}   enableRotate={true} minDistance={2} enableZoom={true} enablePan={false} target={[0, 0, 0]} />
            </Canvas>
                <Loader />
        </div>
    );
}

export default App;
