/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useEffect, useRef} from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as THREE from "three";

const audio = new Audio("/builder.mp3");

export default function Beer(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF(
        "/cons_fin.glb"
    );



    const { actions } = useAnimations(animations, group);
      const animationsArray = Object.keys(actions);
    React.useEffect(() => {
        actions && actions['Cube.006Action'].play();
    }, [actions]);


    const playAudio = () => {
        audio.currentTime = 0;
        audio.play();
    }




    // const playRandomAnimation = () => {
    //     // Ensure no animation is currently playing
    //
    //     //const isAnyAnimationPlaying = Object.values(actions).some(action => action.isRunning());
    //
    //
    //         audio.currentTime = 0;
    //         // Stop all animations to ensure a clean state
    //         // Object.values(actions).forEach(action => action.stop());
    //         //
    //         // // Select and play a random animation
    //         // const animationsArray = Object.keys(actions);
    //         // const randomAnimation = animationsArray[Math.floor(Math.random() * animationsArray.length)];
    //         // console.log("randomAnimation: ", randomAnimation);
    //         const action = actions['EmptyAction.001\n'];
    //         action.stop()
    //         action.reset();
    //         action.play();
    //         audio.play();
    //         action.clampWhenFinished = true;
    //         action.loop = THREE.LoopOnce;
    //
    //         // Speed up the animation - adjust this value as needed
    //         action.timeScale = 5; // This will play the animation at double speed
    //
    // };
    //
    //
    //     playRandomAnimation();





    return (
        <group ref={group} onClick={() => {
            playAudio()
        }}  {...props} dispose={null}>
            <group name="Scene">
                <group
                    name="Empty_(Animation)"
                    position={[-0.036, 2.782, 0]}
                    rotation={[0, 4.45, 0]}
                    scale={[0.46, 2.964, 1.904]}>
                    <mesh
                        name="builder-night"
                        castShadow
                        receiveShadow
                        geometry={nodes['builder-night'].geometry}
                        material={materials['builder-night']}
                        position={[0.578, 0.287, 0]}
                        rotation={[1.571, 0, -1.571]}
                        scale={[1.315, 5.446, 0.845]}
                    />
                    <group name="Card" position={[-0.033, -0.38, 0]} scale={[0.377, 0.543, 0.845]}>
                        <mesh
                            name="Cube001"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube001.geometry}
                            material={materials['Card material']}
                        />
                        <mesh
                            name="Cube001_1"
                            castShadow
                            receiveShadow
                            geometry={nodes.Cube001_1.geometry}
                            material={materials['Glow 1']}
                        />
                    </group>
                    <mesh
                        name="Cube004"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube004.geometry}
                        material={materials['Element material ']}
                        position={[-0.441, -0.024, 0]}
                        rotation={[0, 0, -Math.PI]}
                        scale={[0.044, 0.006, 0.791]}
                    />
                    <mesh
                        name="Cube005"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube005.geometry}
                        material={materials['Element material ']}
                        position={[-0.375, -0.028, 0]}
                        rotation={[-Math.PI / 2, 0, -Math.PI]}
                        scale={[0.044, 0.009, 0.508]}
                    />
                    <mesh
                        name="Cylinder002"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder002.geometry}
                        material={materials['Glow ring material']}
                        position={[-0.376, -0.032, 0]}
                        rotation={[0, 0, -Math.PI / 2]}
                        scale={[0.337, 0.04, 0.525]}
                    />
                    <mesh
                        name="Cylinder003"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder003.geometry}
                        material={materials['Element material ']}
                        position={[-0.368, 0.786, 0]}
                        rotation={[-0.021, -0.009, -1.571]}
                        scale={[0.401, 0.047, 0.624]}
                    />
                    <mesh
                        name="Cylinder004"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder004.geometry}
                        material={materials['Element material ']}
                        position={[-0.368, -0.844, 0]}
                        rotation={[3.121, -0.009, -1.571]}
                        scale={[0.401, 0.047, 0.624]}
                    />
                    <mesh
                        name="Cylinder005"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder005.geometry}
                        material={materials['Element material ']}
                        position={[-0.379, -0.222, 0]}
                        rotation={[3.121, -0.009, -1.571]}
                        scale={[0.415, 0.049, 0.645]}
                    />
                    <mesh
                        name="Cylinder006"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder006.geometry}
                        material={materials['Element material ']}
                        position={[-0.38, 0.162, 0]}
                        rotation={[-0.021, -0.009, -1.571]}
                        scale={[0.415, 0.049, 0.645]}
                    />
                    <mesh
                        name="Line"
                        castShadow
                        receiveShadow
                        geometry={nodes.Line.geometry}
                        material={materials['Element line material']}
                        position={[0.345, -0.565, 0]}
                        rotation={[0, 0, -Math.PI]}
                        scale={[0.011, 0.003, 0.791]}
                    />
                    <mesh
                        name="Line_(Glow_animation)"
                        castShadow
                        receiveShadow
                        geometry={nodes['Line_(Glow_animation)'].geometry}
                        material={materials['Glow element (animation)']}
                        position={[0.345, -0.565, 0.653]}
                        rotation={[0, 0, -Math.PI]}
                        scale={[0.011, 0.003, 0.791]}
                    />
                    <mesh
                        name="Text"
                        castShadow
                        receiveShadow
                        geometry={nodes.Text.geometry}
                        material={nodes.Text.material}
                        position={[0.45, -0.465, 0.003]}
                        rotation={[1.559, 0.199, -1.526]}
                        scale={[0.53, 2.171, 0.349]}
                    />
                    <mesh
                        name="Text_Behind"
                        castShadow
                        receiveShadow
                        geometry={nodes.Text_Behind.geometry}
                        material={materials['Glow Text Behind']}
                        position={[-0.404, -0.023, 0]}
                        rotation={[Math.PI / 2, 0, Math.PI / 2]}
                        scale={[0.451, 1.867, 0.29]}
                    />
                </group>
            </group>
        </group>
    );
}

useGLTF.preload("/cons_fin.glb");